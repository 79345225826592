/**https://www.youtube.com/watch?v=eDw46GYAIDQ */
import React, { useState, useEffect } from 'react'
import styles from "./Stars.module.css"

import empty from "../../assets/starEmpty.svg"
import full from "../../assets/starFull.svg"

export default function Stars({ amountStars }) {
    useEffect(() => {
        setRating(amountStars)
    }, [])

    const [rating, setRating] = useState(null)

    return (
        <div className={styles.mainContainer}>
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;

                return (
                    <label key={i}>
                        <input type="radio"
                            name="rating"
                            value={ratingValue}
                            className={styles.radio}
                        />
                        {ratingValue <= rating
                            ?
                            <img className={styles.star} src={full} alt="full star" />
                            :
                            <img className={styles.star} src={empty} alt="empty star" />
                        }
                    </label>
                )
            })}
        </div>
    )
}

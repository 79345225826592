import broodjeHamburger from "../assets/food/broodjeHamburger.svg";
// import spaghetti from "../assets/food/spaghetti.svg";

export const recepies = [
  {
    id: 0,
    name: "Broodje Hamburger",
    img: broodjeHamburger,
    ingridients: [
      { name: "Hamburger", amounth: "", mesurment: "" },
      { name: "Hamburger broodje", amounth: "", mesurment: "" },
      { name: "Tomaat", amounth: "1", mesurment: "plakje" },
      { name: "Romaine sla", amounth: "1", mesurment: "plakje" },
    ],
    tags: [{ tag: "Zomer" }, { tag: "Vlees" }, { tag: "Bakken" }],
    stars: 4,
  },
];

import React, { useState } from "react";
import styles from "./Landing.module.css";
import Stars from "../stars/Stars";

import arrowUp from "../../assets/arrowUp.svg";
import search from "../../assets/search.svg";
import min from "../../assets/min.svg"
import plus from "../../assets/plus.svg"
import people from "../../assets/people.svg"
import ptrn from "../../assets/linePtrn.svg";
import { recepies } from "../data";

export default function Landing() {
  const [count, setCount] = useState(1)

  return (
    <div className={styles.mainContainer}>
      <img src={ptrn} alt="background ptrn" className={styles.ptrn} />
      <div className={styles.headerContainer}>
        <div className={styles.filterContainer}>
          <p>Filter</p>
          <img src={arrowUp} alt="arrow up icon" />
        </div>
        <div className={styles.searchContainer}>
          <img src={search} alt="search icon" />
          <input placeholder="Zoek jouw gerecht" id={styles.input}></input>
        </div>
      </div>
      {recepies?.map((element) => {
        return (
          <div key={element.id} className={styles.mainContent}>
            <div className={styles.middelContainer}>
              <div className={styles.leftSide}>
                <p className={styles.title}>{element.name}</p>
                <div className={styles.tagContainer}>
                  {recepies[element.id].tags?.map((element) => {
                    return (
                      <div key={element.tag[0]} className={styles.tag}>
                        {element.tag}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.rightSide}>
                <img src={element.img} alt={element.title} className={styles.img} />
              </div>
            </div>
            <div className={styles.bottomContainer}>
              <div className={styles.bottomLeft}>
                <button className={styles.btn}>Nieuw gerecht</button>
                <div className={styles.peopleContainer}>
                  <img onClick={() => { if (count > 1) { setCount(count => count - 1) } }} src={min} alt="remove person" className={styles.amounth} />
                  <p id={styles.count}>{count}</p>
                  <img onClick={() => { if (count < 99) { setCount(count => count + 1) } }} src={plus} alt="add person" className={styles.amounth} />
                  <img src={people} alt="person icon" className={styles.peopleIcon} />
                </div>
              </div>
              <div className={styles.starContainer}>
                <Stars amountStars={element.stars} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
